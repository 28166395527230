import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { CheckCircleIcon } from '@heroicons/react/outline';

const metrics = [
  {
    id: 1,
    stat: 'Bespaar 30 minuten per dag',
    emphasis: 'Minder tijd',
    rest: 'kwijt aan onnodige vragen, belletjes, mailtjes en appjes.',
  },
  {
    id: 2,
    stat: '13% minder faalkosten',
    emphasis: '',
    rest: 'Door heldere communicatie en planning.',
  },
  {
    id: 3,

    stat: 'Voorbereid op de WKB',
    emphasis: 'Met Homigo ',
    rest: 'bereid je je voor op de WKB door van elk project een digitaal dossier op te bouwen.',
  },
  {
    id: 4,
    stat: '+ 2340 Projecten',
    emphasis: '',
    rest: 'Zijn succesvol afgerond met Homigo.',
  },
  {
    id: 5,

    stat: '+1150 Aannemers',
    emphasis: '',
    rest: 'Verbouwen als een baas met Homigo.',
  },
  {
    id: 6,
    stat: 'Tijd vrij gemaakt op de vrijdagmiddag voor bier met je bouw amigo’s.',
    emphasis: '',
    rest: 'Door alle bespaarde tijd.',
  },
];

export default function Stats() {
  return (
    <div className="relative bg-secondary">
      <div className="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
          <div className="h-full xl:relative xl:col-start-2">
            {/* <img
              className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
              src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
              alt="People working on laptops"
            /> */}
            <StaticImage
              className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
              src="../img/bg.png"
              alt=""
            />
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-secondary xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
            />
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          {/* <h2 className="text-sm font-semibold text-indigo-300 tracking-wide uppercase">
            Belangrijke cijfers
          </h2> */}
          <p className="mt-3 text-3xl font-extrabold text-white">Wat biedt Homigo jou?</p>
          <p className="mt-5 text-lg text-gray-300">
            Je weet wat we doen, maar hoe maken wij jouw leven nou daadwerkelijk beter?
          </p>
          <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-3">
            {metrics.map((item) => (
              <p key={item.id}>
                <span className="inline text-xl font-bold text-white">
                  <CheckCircleIcon className="mr-2 inline h-6 w-6 text-white" />
                  {item.stat}
                </span>
                <span className="pl-8 mt-1 block text-base text-gray-300">
                  <span className="font-medium text-white">{item.emphasis}</span> {item.rest}
                </span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
