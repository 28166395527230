import React from 'react';
import { graphql } from 'gatsby';
import HeroAlternative from '../components/HeroAlternative';
import LogoPartners from '../components/LogoPartners';
import Features from '../components/Features';
import LogoCustomers from '../components/LogoCustomers';
import FeaturesDetailed from '../components/FeaturesDetailed';
import Stats from '../components/Stats';
import BookDemo from '../components/BookDemo';
import BlogList from '../components/BlogList';
import BenefitCta from '../components/Benefit/BenefitCta';
import Layout from '../components/layout';
import { SEO } from '../components/SEO/SEO';

const IndexPage = ({ data, location }) => (
  <Layout>
    <SEO location={location} pageMetadata={{ title: 'Verbouwen zonder gedoe!' }} />
    <HeroAlternative />
    <LogoPartners />
    <Features />
    <BenefitCta
      heading={
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Meer omzet, minder faalkosten?</span>
          <span className="">Download</span>
          <span className="text-primary"> Homigo </span>
          <span className="">vandaag nog.</span>
        </h2>
      }
      subheading="Homigo is verkrijgbaar voor zowel IOS als Android en is ook te gebruiken als webversie op je computer."
    />
    <LogoCustomers />
    <FeaturesDetailed />

    <Stats />
    <BenefitCta
      heading={
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Meer omzet, minder faalkosten?</span>
          <span className="">Download</span>
          <span className="text-primary"> Homigo </span>
          <span className="">vandaag nog.</span>
        </h2>
      }
      subheading="Homigo is verkrijgbaar voor zowel IOS als Android en is ook te gebruiken als webversie op je computer."
    />
    <BlogList blogPosts={data.allContentfulBlogPost.edges} />
    <BookDemo />
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query BlogSummaryQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }, limit: 3) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "DD MMMM, YYYY", locale: "nl")
          body {
            childMarkdownRemark {
              excerpt
              longExcerpt: excerpt(pruneLength: 260)
            }
          }

          heroImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          author {
            name
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          category {
            id
            name
            slug
          }
        }
      }
    }
  }
`;
