import React from 'react';

const features = [
  {
    name: 'Maak een project',
    number: '1',
    description:
      'Meerdere projecten tegelijk managen? Met de overzichtsplanner van Homigo behoud je ten alle tijden het overzicht.',
  },
  {
    name: 'Nodig teamleden uit',
    number: '2',
    description:
      'Nodig alle projectleden uit binnen Homigo en bepaal zelf wie toegang heeft tot welke informatie.',
  },
  {
    name: ' Plan, chat, deel en houd iedereen op de hoogte',
    number: '3',
    description:
      'Chat over belangrijke zaken en deel alle belangrijke bestanden via Homigo. Zo blijft iedereen op de hoogte',
  },
];

export default function Features() {
  return (
    <div className="bg-white pb-10">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-12 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div className="lg:col-span-2">
          <p className="mt-2 text-3xl font-extrabold text-gray-900">
            Alles wat je nodig hebt op één plek.
          </p>
          <div className="relative mx-auto mt-10 w-full sm:h-12">
            <div className="md:hidden">
              <iframe
                className="rounded-md shadow-lg"
                width="100%"
                height="193"
                src="https://www.youtube.com/embed/uobNP4sSzEA?rel=0&amp;autoplay=1&mute=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="hidden md:block">
              <iframe
                className="rounded-md shadow-lg"
                width="100%"
                height="450"
                src="https://www.youtube.com/embed/uobNP4sSzEA?rel=0&amp;autoplay=1&mute=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
        <div className="mt-12 lg:mt-20">
          <p className="mb-4 text-xl font-extrabold text-gray-900">Start in 3 makkelijke stappen</p>

          <dl className="mt-10 space-y-8">
            {features.map((feature) => (
              <div key={feature.id} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                    <p className="font-bold text-2xl">{feature.number}</p>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
