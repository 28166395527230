import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function LogoPartners() {
  return (
    <div className="bg-white ">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <p className="mt-2 text-3xl font-extrabold text-gray-900">Samenwerking met de bouw</p>
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-4">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 image-effect-container">
            <StaticImage src="../img/logos/logo1.png" alt="Zelfstandinden Bouw" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 image-effect-container">
            <StaticImage src="../img/logos/logo2.png" alt="Stukbouw" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 image-effect-container">
            <StaticImage src="../img/logos/logo3.png" alt="Kwaliteits vakman" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1 image-effect-container">
            <StaticImage src="../img/logos/logo4.png" alt="Bouwend Nederland" />
          </div>
        </div>
      </div>
    </div>
  );
}
