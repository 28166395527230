import React from 'react';
import { StarIcon } from '@heroicons/react/solid';
import { StaticImage } from 'gatsby-plugin-image';
import Appstore from '../img/appstore.png';
import Googleplay from '../img/googleplay.png';

export default function HeroAlternative() {
  return (
    <div className="bg-blue-50">
      <div className="pt-8 overflow-hidden lg:relative lg:py-48">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div className="-mt-20">
              <div>
                <span className="rounded bg-opacity-10 px-2.5 py-1 text-xs font-semibold text-secondary tracking-wide uppercase" />
                {/* <span className="inline-flex items-center text-sm font-medium text-indigo-600 space-x-1">
                    <span>Just shipped version 0.1.0</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </span> */}
              </div>
              <div className="mt-12 lg:mt-6 sm:max-w-xl">
                <h1>
                  <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-5xl">
                    <span className="text-secondary">Homigo, verbouwen </span>
                    <span className=" text-primary">zonder gedoe!</span>
                  </span>
                </h1>
                <p className="mt-6 text-xl text-gray-500">
                  Met Homigo verbouw je slimmer en heb je alle informatie op één plek.
                </p>
              </div>
              <div className="flex mt-8 sm:max-w-lg">
                <a href="https://apps.apple.com/us/app/mijn-homerun/id1514372640">
                  <img className="pr-4" id="appstore" src={Appstore} alt="Appstore badge" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.mijnhomerun.homerun&hl=en&gl=US">
                  <img className="pl-4" id="googleplay" src={Googleplay} alt="GooglePlay badge" />
                </a>
              </div>
              <div className="mt-6">
                <div className="inline-flex items-center divide-x divide-gray-300">
                  <div className="flex-shrink-0 flex pr-5">
                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                    <span className="font-medium text-gray-900">
                      Door onze gebruikers met 5 sterren beoordeeld
                    </span>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block" />
            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
              {/* <img
                className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                src="https://tailwindui.com/img/component-images/top-nav-with-multi-column-layout-screenshot.jpg"
                alt=""
              /> */}
              <StaticImage
                className="w-full   lg:w-auto lg:max-w-none"
                src="../img/home_hero.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
