import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function LogoCustomers() {
  return (
    <div className="bg-secondary -mt-16">
      <div className="max-w-7xl mx-auto pb-16 px-4  sm:px-6 lg:px-8">
        <p className="text-center text-lg leading-6 text-gray-300">
          Meer dan 1.000 aannemers gebruiken Homigo
        </p>
        <div className="flow-root mt-8 lg:mt-10">
          <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <StaticImage src="../img/logo_clients/logo1.png" alt="" />
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <StaticImage src="../img/logo_clients/logo2.png" alt="" />
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <StaticImage src="../img/logo_clients/logo3.png" alt="" />
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <StaticImage src="../img/logo_clients/logo4.png" alt="" />
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <StaticImage src="../img/logo_clients/logo5.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
