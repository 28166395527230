import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  CalendarIcon,
  ChatAlt2Icon,
  DocumentIcon,
  ShareIcon,
  UserAddIcon,
} from '@heroicons/react/outline';

const transferFeatures = [
  {
    id: 1,
    name: 'Overzichtsplanning',
    description:
      'Heb je liever een groot overzicht van de planning? En wil je de activiteiten die afhankelijk van elkaar zijn goed in elkaar over laten lopen? Gebruik dan de strokenplanning.',
    icon: CalendarIcon,
  },
  {
    id: 2,
    name: 'Toewijzing van activiteiten',
    description:
      'Wijs kalenderactiviteiten toe aan onderaannemers of werknemers. Op die manier kun je ze inplannen op verschillende projecten en weten ze waar, wanneer en hoe.',
    icon: UserAddIcon,
  },
  {
    id: 3,
    name: 'Gedeelde planning',
    description:
      'Geef alle projectleden inzicht in de planning. Het is niet meer nodig om planningen te printen of te mailen. Wanneer jij een aanpassing doet, is deze direct voor iedereen inzichtelijk.',
    icon: ShareIcon,
  },
];
const communicationFeatures = [
  {
    id: 1,
    name: 'Afspraken',
    description:
      'Leg een meer- of minderwerkafspraak vast, zodat je achteraf geen discussie hebt over wat is afgesproken. Bepaal de prijs en verstuur de afspraak naar de klant. De klant kan de afspraak vervolgens digitaal accepteren.',
    icon: DocumentIcon,
  },
  {
    id: 2,
    name: '(Groeps)chats',
    description:
      'Start 1 op 1 chats of groepschat met onderaannemers, medewerkers en klant. Upload video’s, foto’s, en documenten. Informeer op deze manier gemakkelijk meerdere projectleden tegelijkertijd.',
    icon: ChatAlt2Icon,
  },
];
const manageFeatures = [
  {
    id: 1,
    name: 'Digitaal dossier',
    description:
      'Archiveer je projecten met alle documentatie zodat je een dossier opbouwt per project. Zo voldoe je aan de WKB. Wanneer je later iets terug moet zoeken over bijvoorbeeld een gemaakte afspraak is alles netjes gedocumenteerd.',
    icon: ShareIcon,
  },
  {
    id: 2,
    name: 'Meerwerk afspraken-overzicht',
    description:
      'In het afspraken-overzicht zie je alle gemaakte afspraken terug. Netjes gesorteerd op open, geaccepteerde of geweigerde afspraken. Ook tellen we de kosten voor je op, zodat je een duidelijk overzicht hebt.',
    icon: CalendarIcon,
  },
  {
    id: 3,
    name: 'Urenoverzicht',
    description:
      'Als project-admin heb je een aparte pagina met het overzicht van de uren van alle projectleden. Je ziet hier alle ingediende, afgewezen en goedgekeurde uren die je eenvoudig kunt filteren. Ook zie je het totaal aantal goedgekeurde uren per project.',
    icon: UserAddIcon,
  },
];

export default function FeaturesDetailed() {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Dé app voor <span className="text-primary">verbouw</span> en{' '}
            <span className="text-primary">renovatie</span>
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Geen gedoe meer met losse briefjes, planningen, bouwtekeningen en honderd telefoontjes
            op een dag. Manage je projecten met gemak en creëer duidelijkheid voor alle betrokkenen.
          </p>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Plan op eenvoudige wijze
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              Bespaar 2 keer zoveel tijd bij het plannen, het aanpassen en het communiceren over de
              planning met alle deelnemers van je projecten. Geen gedoe meer met planningen
              uitprinten, delen en wijzigingen aanbrengen. Met Homigo weet iedereen op elk moment
              waar hij aan toe is.
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <StaticImage src="../img/home_planning.png" alt="" />
          </div>
        </div>

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                Deel belangrijke informatie met projectleden
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                Om een project goed te laten verlopen, moet iedereen weten waar hij aan toe is. Door
                belangrijke informatie, bestanden en afspraken te delen binnen Homigo, is iedereen,
                altijd op de hoogte.
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <StaticImage src="../img/home_sharing.png" alt="" />
            </div>
          </div>
        </div>
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Manage je klussen op een slimme manier
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              Grip op je projecten, tijd en kosten? Met Homigo wordt bouwen alleen maar leuker. Door
              het overzicht over al je projecten tegelijk te bewaren, houd jij meer tijd over om te
              bouwen.
            </p>

            <dl className="mt-10 space-y-10">
              {manageFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <StaticImage src="../img/home_files.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
